import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [
    "form",
    "container",
    "submit",
    "payment",
    "paypalButton",
    "orderId",
  ]

  connect () {
    this.timeout = null
    this.formTarget.reset()

    this.addFormChangeListeners()
  }

  loadPaypalScript (totalAmount) {
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: totalAmount
                }
              }
            ]
          })
        },
        style: {
          color: "gold",
          shape: "rect",
          layout: "horizontal",
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(() => {
            this.orderIdTarget.value = data.orderID

            this.formTarget.submit()
          })
        },
      })
      .render(this.paypalButtonTarget)
  }

  submit (event) {
    if (event) event.preventDefault()

    const isSubmitButton = event ? event.target === this.submitTarget : false
    const isFullSubmit = event ? event.target.dataset.all === "true" : false

    this.showLoader()

    const formData = new FormData(this.formTarget)
    formData.append('isSubmitButton', isSubmitButton)

    axios
      .post(this.formTarget.action, formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" }
      })
      .then((response) => this.handleResponse(response, isFullSubmit, isSubmitButton))
      .catch((error) => console.error("Erreur lors de l'envoi du formulaire:", error))
      .finally(() => this.hideLoader())
  }

  handleResponse (response, isFullSubmit, isSubmitButton) {
    const dataContainer = this.extractDataFromResponse(response.data.content)

    this.containerTarget.innerHTML = dataContainer.querySelector(
      '[data-front--form--reservation-target="container"]'
    ).innerHTML

    this.addFormChangeListeners()

    if (response.data.isValid && isSubmitButton) {
      this.total = response.data.subtotal
      this.showPaymentOptions(response.data.total)
    }
  }

  showPaymentOptions (totalAmount) {
    this.submitTarget.classList.add("d-none")
    this.paymentTarget.classList.remove("d-none")
    this.loadPaypalScript(totalAmount)
  }

  addFormChangeListeners () {
    const formFields = this.formTarget.querySelectorAll("input, select, textarea")

    formFields.forEach((field) => {
      field.addEventListener('input', () => {
        this.submitTarget.classList.remove("d-none")
        this.paymentTarget.classList.add("d-none")
      })
    })
  }

  extractDataFromResponse (htmlResponse) {
    return new DOMParser().parseFromString(htmlResponse, "text/html")
  }

  showLoader () {
    document.querySelector(".loader").classList.remove("opacity-0", "z-n1")
    document.querySelector(".loader").classList.add("z-3")
  }

  hideLoader () {
    document.querySelector(".loader").classList.add("opacity-0", "z-n1")
    document.querySelector(".loader").classList.remove("z-3")
  }
}
