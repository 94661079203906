import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ["form", "products"]

  connect () {
    this.formTarget.reset()
  }

  submit (event) {
    event.preventDefault()

    const formData = new FormData(this.formTarget)

    this.showLoader()

    axios
      .post(this.formTarget.action, formData, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then((response) => {
        const dataContainer = this.extractDataContainerFromResponse(
          response.data
        )
        this.productsTarget.innerHTML = dataContainer.querySelector(
          '[data-front--form--product-filter-target="products"]'
        ).innerHTML
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du formulaire:", error)
      })
      .finally(() => {
        this.hideLoader()
      })
  }

  extractDataContainerFromResponse (htmlResponse) {
    return new DOMParser().parseFromString(htmlResponse, "text/html")
  }

  showLoader () {
    document.querySelector(".loader").classList.remove("opacity-0", "z-n1")
    document.querySelector(".loader").classList.add("z-3")
  }

  hideLoader () {
    document.querySelector(".loader").classList.add("opacity-0", "z-n1")
    document.querySelector(".loader").classList.remove("z-3")
  }
}
