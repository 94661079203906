import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input"]

  connect () {
    const input = this.element

    input.addEventListener('input', () => {
      this.formatPhone()
    })
  }

  formatPhone () {
    let input = this.inputTarget.value

    input = input.replace(/\D/g, '')

    if (input.length > 10) {
      input = input.substring(0, 10)
    }

    input = input.replace(/(\d{2})(?=\d)/g, '$1 ')

    this.inputTarget.value = input
  }
}
