import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ["input", "suggestions", "zipCode"]

  connect() {
      this.timeout = null

      this.getSuggestions()
  }

  getSuggestions() {
      const query = this.inputTarget.value

      if (query.length < 3) {
          this.suggestionsTarget.classList.add("d-none")
          return
      }

      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
          this.fetchCitySuggestions(query)
      }, 300)
  }

  fetchCitySuggestions(query) {
      const url = `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}`

      fetch(url)
          .then(response => response.json())
          .then(data => {
                const groupedCities = data.features.reduce((acc, feature) => {
                  const city = feature.properties.city
                  if (!acc[city]) {
                    acc[city] = []
                  }
                  acc[city].push(feature)
                  return acc
                }, {})

                const groupedCitiesArray = Object.values(groupedCities).map(group => group[0])

                this.displaySuggestions(groupedCitiesArray)
          })
          .catch(error => {
              console.error("Erreur lors de la récupération des suggestions des ville :", error)
          })
  }

  displaySuggestions(cities) {
      this.clearSuggestions()
      this.suggestionsTarget.classList.remove("d-none")

      if (cities.length === 0) {
          this.suggestionsTarget.innerHTML = "<div class='suggestion-item'>Aucune ville trouvée</div>"
          return
      }

      cities.forEach(city => {
          const suggestionItem = document.createElement("div")
          suggestionItem.textContent = city.properties.city + " (" + city.properties.postcode + ")"
          suggestionItem.classList.add("suggestion-item")
          suggestionItem.addEventListener("click", () => {
              this.selectCity(city)
          })

          this.suggestionsTarget.appendChild(suggestionItem)
      })
  }

  clearSuggestions() {
      this.suggestionsTarget.innerHTML = ""
  }

  selectCity(city) {
      this.inputTarget.value = city.properties.city
      this.zipCodeTarget.value = city.properties.postcode
      this.clearSuggestions()
      this.suggestionsTarget.classList.add("d-none")
  }
}
