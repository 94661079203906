import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.maxOrderQuantity = parseInt(this.inputTarget.getAttribute('max'))
  }

  plus () {
    if (parseInt(this.inputTarget.value) < this.maxOrderQuantity) {
      this.inputTarget.value = parseInt(this.inputTarget.value) + 1
    }
  }

  minus () {
    if (parseInt(this.inputTarget.value) > 1) {
      this.inputTarget.value = parseInt(this.inputTarget.value) - 1
    }
  }
}
