import { Controller } from '@hotwired/stimulus'
import { Toast, Offcanvas } from 'bootstrap'
import axios from 'axios'

export default class extends Controller {

  connect () {
    this.offcanvasElement = document.querySelector("[data-cart-offcanvas]")
    this.offcanvasInstance = new Offcanvas(this.offcanvasElement)

    this.offcanvasElement.addEventListener("shown.bs.offcanvas", () => {
      this.isOffcanvasShown = true
      this.attachDismissEvent()
    })
    this.offcanvasElement.addEventListener("hidden.bs.offcanvas", () => {
      this.isOffcanvasShown = false
    })
  }

  submit (event) {
    event.preventDefault()

    this.showLoader()

    const form = event.target.closest("form")

    const url = form.action
    const formData = new FormData(form)

    axios.post(url, formData, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        this.showCart()
        this.createToast(response.data.message, "Succès")
        this.hideLoader()
      })
      .catch((error) => {
        console.log(error)
        this.createToast(error.response?.data?.message || "Une erreur est survenue")
        if (!this.offcanvasElement.classList.contains("show")) {
          this.offcanvasInstance.show()
        }
      })
  }

  showCart () {
    axios
      .get("/cart", {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then((response) => {
        const dataContainer = this.extractDataContainerFromResponse(response.data)
        this.offcanvasElement.querySelector('.offcanvas-body').innerHTML =
          dataContainer.querySelector("[data-cart-offcanvas] .offcanvas-body").innerHTML

      })
      .catch((error) => {
        console.log(error)
        this.createToast(error.response?.data?.message || "Une erreur est survenue")
      })
      .finally(() => {
        if (!this.offcanvasElement.classList.contains("show")) {
          this.offcanvasInstance.show()
        }
      })
  }

  createToast (message, type = "Erreur") {
    const toastElement = document.createElement("div")
    toastElement.className = `toast`
    toastElement.setAttribute("role", "alert")
    toastElement.setAttribute("aria-live", "assertive")
    toastElement.setAttribute("aria-atomic", "true")
    toastElement.innerHTML = `
      <div class="toast-header bg-primary text-white fs-5">
        <strong class="me-auto ms-2">${type}</strong>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body" style="font-size: 1.2rem;">
          ${message}
        </div>
      </div>
    `

    const toastContainer = document.querySelector(".toast-container")
    toastContainer.appendChild(toastElement)

    const toast = new Toast(toastElement)
    toast.show()

    toastElement.addEventListener("hidden.bs.toast", () => {
      toastElement.remove()
    })
  }

  extractDataContainerFromResponse (htmlResponse) {
    const html = new DOMParser().parseFromString(htmlResponse, "text/html")
    return html
  }

  attachDismissEvent () {
    const closeButton = this.offcanvasElement.querySelector('[data-bs-dismiss="offcanvas"]')
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        this.offcanvasInstance.hide()
      })
    }
  }

  showLoader () {
    if (document.querySelector(".loader")) {
      document.querySelector(".loader").classList.remove("opacity-0", "z-n1")
      document.querySelector(".loader").classList.add("z-3")
    }
  }

  hideLoader () {
    if (document.querySelector(".loader")) {
      document.querySelector(".loader").classList.add("opacity-0", "z-n1")
      document.querySelector(".loader").classList.remove("z-3")
    }
  }
}
