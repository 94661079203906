import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ["input", "suggestions", "zipCode", "city"]

  connect () {
    this.timeout = null

    this.getSuggestions()
  }

  getSuggestions () {
    const query = this.inputTarget.value

    if (query.length < 3) {
      this.suggestionsTarget.classList.add("d-none")
      return
    }

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.fetchAddressSuggestions(query)
    }, 300)
  }

  fetchAddressSuggestions (query) {
    const url = `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}`

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.displaySuggestions(data.features)
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des suggestions d'adresse :", error)
      })
  }

  displaySuggestions (addresses) {
    this.clearSuggestions()
    this.suggestionsTarget.classList.remove("d-none")

    if (addresses.length === 0) {
      this.suggestionsTarget.innerHTML = "<div class='suggestion-item'>Aucune adresse trouvée</div>"
      return
    }

    addresses.forEach(address => {
      const suggestionItem = document.createElement("div")
      suggestionItem.textContent = address.properties.label
      suggestionItem.classList.add("suggestion-item")
      suggestionItem.addEventListener("click", () => {
        this.selectAddress(address)
      })

      this.suggestionsTarget.appendChild(suggestionItem)
    })
  }

  clearSuggestions () {
    this.suggestionsTarget.innerHTML = ""
  }

  selectAddress (address) {
    this.inputTarget.value = address.properties.name
    this.zipCodeTarget.value = address.properties.postcode
    this.cityTarget.value = address.properties.city
    this.clearSuggestions()
    this.suggestionsTarget.classList.add("d-none")
  }
}
