import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [
    "form",
    "container",
    "resume",
    "address",
    "suggestions",
    "zipCode",
    "city",
    "submit",
    "payment",
    "paypalButton",
    "orderId",
  ]

  connect () {
    this.timeout = null
    this.formTarget.reset()

    this.addFormChangeListeners()

    this.total = null
  }

  loadPaypalScript (totalAmount) {
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: totalAmount
                }
              }
            ]
          })
        },
        style: {
          color: "gold",
          shape: "rect",
          layout: "horizontal",
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(() => {
            this.orderIdTarget.value = data.orderID

            this.formTarget.submit()
          })
        },
        onClick: () => {
          axios.post('/cart/validate', {
            headers: { "X-Requested-With": "XMLHttpRequest" }
          })
        },
        onCancel: () => {
          axios.post('/cart/cancel', {
            headers: { "X-Requested-With": "XMLHttpRequest" }
          })
        }
      })
      .render(this.paypalButtonTarget)
  }

  submit (event) {
    if (event) event.preventDefault()

    const isSubmitButton = event ? event.target === this.submitTarget : false
    const isFullSubmit = event ? event.target.dataset.all === "true" : false

    this.showLoader()

    const formData = new FormData(this.formTarget)
    formData.append('isSubmitButton', isSubmitButton)

    axios
      .post(this.formTarget.action, formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" }
      })
      .then((response) => this.handleResponse(response, isFullSubmit, isSubmitButton))
      .catch((error) => console.error("Erreur lors de l'envoi du formulaire:", error))
      .finally(() => this.hideLoader())
  }

  handleResponse (response, isFullSubmit, isSubmitButton) {
    const dataContainer = this.extractDataFromResponse(response.data.content)

    if (isFullSubmit || isSubmitButton) {
      this.containerTarget.innerHTML = dataContainer.querySelector(
        '[data-front--form--order-target="container"]'
      ).innerHTML
    } else {
      this.resumeTarget.innerHTML = dataContainer.querySelector(
        '[data-front--form--order-target="resume"]'
      ).innerHTML
    }

    this.addFormChangeListeners()

    if (!response.data.isValid) {
      document.querySelector(".container").scrollIntoView({ behavior: "smooth" })
    }

    if (!response.data.isClickAndCollect) {
      this.addressTarget.addEventListener("input", () => this.getSuggestions())
    }

    if (response.data.isValid && isSubmitButton) {
      this.total = response.data.subtotal
      this.showPaymentOptions(response.data.total)
    }

  }

  showPaymentOptions (totalAmount) {
    this.submitTarget.classList.add("d-none")
    this.paymentTarget.classList.remove("d-none")
    this.loadPaypalScript(totalAmount)
  }

  addFormChangeListeners () {
    const formFields = this.formTarget.querySelectorAll("input, select, textarea")

    formFields.forEach((field) => {
      field.addEventListener('input', () => {
        this.submitTarget.classList.remove("d-none")
        this.paymentTarget.classList.add("d-none")
      })
    })
  }

  extractDataFromResponse (htmlResponse) {
    return new DOMParser().parseFromString(htmlResponse, "text/html")
  }

  showLoader () {
    document.querySelector(".loader").classList.remove("opacity-0", "z-n1")
    document.querySelector(".loader").classList.add("z-3")
  }

  hideLoader () {
    document.querySelector(".loader").classList.add("opacity-0", "z-n1")
    document.querySelector(".loader").classList.remove("z-3")
  }

  getSuggestions () {
    const query = this.addressTarget.value

    if (query.length < 3) {
      this.suggestionsTarget.classList.add("d-none")
      return
    }

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.fetchAddressSuggestions(query)
    }, 300)
  }

  fetchAddressSuggestions (query) {
    const url = `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}`

    fetch(url)
      .then((response) => response.json())
      .then((data) => this.displaySuggestions(data.features))
      .catch((error) =>
        console.error("Erreur lors de la récupération des suggestions d'adresse :", error)
      )
  }

  displaySuggestions (addresses) {
    this.clearSuggestions()
    this.suggestionsTarget.classList.remove("d-none")

    if (addresses.length === 0) {
      this.suggestionsTarget.innerHTML = "<div class='suggestion-item'>Aucune adresse trouvée</div>"
      return
    }

    addresses.forEach((address) => {
      const suggestionItem = document.createElement("div")
      suggestionItem.textContent = address.properties.label
      suggestionItem.classList.add("suggestion-item")
      suggestionItem.addEventListener("click", () => this.selectAddress(address))

      this.suggestionsTarget.appendChild(suggestionItem)
    })
  }

  clearSuggestions () {
    this.suggestionsTarget.innerHTML = ""
  }

  selectAddress (address) {
    this.addressTarget.value = address.properties.name
    this.zipCodeTarget.value = address.properties.postcode
    this.cityTarget.value = address.properties.city
    this.clearSuggestions()
    this.suggestionsTarget.classList.add("d-none")

    this.submit(null)
  }
}
