import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ["input"]

  connect () {
    this.createShowPasswordButton()
  }

  createShowPasswordButton () {
    const button = document.createElement("a")
    button.classList.add("btn", "btn-link", "px-0", "text-dark")
    button.href = "#"
    button.type = "button"
    button.innerHTML = this.showPasswordLabel()
    button.addEventListener("click", this.togglePassword.bind(this))
    this.inputTarget.insertAdjacentElement("afterend", button)
  }

  togglePassword (event) {
    event.preventDefault()

    const input = this.inputTarget
    if (input.type === "password") {
      input.type = "text"
      event.target.innerHTML = this.hidePasswordLabel()
    } else {
      input.type = "password"
      event.target.innerHTML = this.showPasswordLabel()
    }
  }

  showPasswordLabel() {
    return document.querySelector('[data-show-label]').getAttribute("data-show-label")
  }

  hidePasswordLabel() {
    return document.querySelector('[data-hide-label]').getAttribute("data-hide-label")
  }
}
