import { Controller } from '@hotwired/stimulus'
import axios from 'axios'

export default class extends Controller {
  static values = {
    route: String
  }

  connect () {
    this.loadContent()
  }

  loadContent () {
    const url = this.routeValue

    axios.get(url)
      .then(response => {
        this.element.innerHTML = response.data
      })
      .catch(error => {
        console.error('Erreur lors du chargement des statistiques:', error)
        this.element.innerHTML = `<p>Une erreur est survenue lors du chargement des données.</p>`
      })
  }
}
