import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'shortcutContainer']

  connect () {
    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        event.preventDefault()

        if (this.inputTarget) {
          this.inputTarget.focus()
        }
      }
    })

    this.inputTarget.addEventListener('focusin', () => {
      this.shortcutContainerTarget.classList.add('d-none')
    })

    this.inputTarget.addEventListener('focusout', () => {
      this.shortcutContainerTarget.classList.remove('d-none')
    })
  }
}
